import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';


// Redux

// ----------------------------------------------------------------------

let initialState = {
    searchTerm: "",
    inputValue: "",
    statusFilter: "Unpaid",
    typeFilter: "All",
    dueFilter: "All",
    sortColumn: "",
    sortDirection: "asc",
    currentPage: 1,
    scrollPos: 0,
    LastStatus: "Open",
    invoiceList: { 'rows': [] },

    invoiceNum: null,
    invbillingforecast: [],
    invSummaryCardData: {},
    invSummaryTableData: {},
    invLogbookData: {},
    statusActivityMap: [],
    isinvoiceListLoading: false,
    isinvSummaryCardLoading: false,
    isinvSummaryTableLoading: false,
    isinvoiceLogBookLoading: true,
    isinvSummaryCardLoading: false,
    isinvSummaryTableLoading: false,
    error: null,
    errorInvNum: null,
    showSuccess: false,
};

export const slice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        // set filter values in redux
        setInvoiceSummaryCards(state, action) {
            state.invSummaryCardData = action.payload;
            state.isinvSummaryCardLoading = false;
        },

        setInvoiceSummaryTable(state, action) {
            state.invSummaryTableData = action.payload;
            state.isinvSummaryTableLoading = false;
        },

        setInvoiceLogbookData(state, action) {
            state.invLogbookData = action.payload;
        },

        setInvoiceLogbookFilters(state, action) {
            state.statusActivityMap = action.payload;
        },

        setInvoiceList(state, action) {
            state.invoiceList = action.payload;
            state.isinvoiceListLoading = false;
        },

        setBillingForecast(state, action) {
            state.invbillingforecast = action.payload;
        },

        setInvoiceNum(state, action) {
            state.invoiceNum = action.payload;
        },

        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },

        setinputValue(state, action) {
            state.inputValue = action.payload;
        },

        setStatusFilter(state, action) {
            state.statusFilter = action.payload;
        },

        setTypeFilter(state, action) {
            state.typeFilter = action.payload;
        },

        setDueFilter(state, action) {
            state.dueFilter = action.payload;
        },

        setSortDirection(state, action) {
            state.sortDirection = action.payload;
        },

        setSortColumn(state, action) {
            state.sortColumn = action.payload;
        },

        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },

        setScrollPos(state, action) {
            state.scrollPos = action.payload;
        },

        setLastStatus(state, action) {
            state.LastStatus = action.payload;
        },

        setInvoiceListLoading(state, action) {
            state.isinvoiceListLoading = action.payload;
        },

        setInvSummaryCardLoading(state, action) {
            state.isinvSummaryCardLoading = action.payload;
        },

        setInvSummaryTableLoading(state, action) {
            state.isinvSummaryTableLoading = action.payload;
        },

        setInvLogbookLoading(state, action) {
            state.isinvoiceLogBookLoading = action.payload;
        },

        showSuccess(state, action) {
            state.showSuccess = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isinvSummaryTableLoading = false;
            state.error = action.payload;
        },

        hasErrorNum(state, action) {
            state.errorInvNum = action.payload;
        }

    },
});

export const {
    setinputValue,
    setCurrentPage,
    setLastStatus,
    setSortColumn,
    setSortDirection,
    setPaginationLoading,
    setSearchTerm,
    setStatusFilter,
    setTypeFilter,
    setDueFilter,
    setInvoiceList,
    hasError,
} = slice.actions;

export function getInvoiceList(statusFilter, statusType, statusDue, searchTerm, sortColumn, sortDirection, rowsPerPage, page) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvoiceListLoading(true));
        try {
            // Construct the query string
            const queryParams = new URLSearchParams({
                statusFilter,
                statusType,
                statusDue,
                page,
                rowsPerPage,
                searchTerm,
                sortColumn,
                sortDirection
            }).toString();

            const response = await axios.get(`${API_URL}/api/invoice/list/all?${queryParams}`);
            dispatch(slice.actions.setInvoiceList(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvSummaryTable() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvSummaryTableLoading(true));
        try {
            const response = await axios.get(`${API_URL}/api/invoicing/summary/table`);
            dispatch(slice.actions.setInvoiceSummaryTable(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getInvSummaryCards() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvSummaryCardLoading(true));
        try {
            const response = await axios.get(`${API_URL}/api/invoicing/summary/cards`);
            dispatch(slice.actions.setInvoiceSummaryCards(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getLogBook() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setInvLogbookLoading(true));
        try {
            const response = await axios.get(`${API_URL}/api/invoice/invoiceProfile/invoiceLogBook`);
            dispatch(slice.actions.setInvoiceLogbookData(response.data.data));
            dispatch(slice.actions.setInvLogbookLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setInvLogbookLoading(false));
        }
    };
}

export function getCurrentInvNum() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.get(`${API_URL}/api/Admin/Invoice/currentInvoiceNum`);
            dispatch(slice.actions.setInvoiceNum(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBillingForecast(Year) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.get(`${API_URL}/api/invoicing/billing_forecast?Year=${Year}`);
            dispatch(slice.actions.setBillingForecast(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postInvoiceNumUnpaid(InvoiceNumber) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/Admin/Invoice/unpaidReset`,
                InvoiceNumber, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            dispatch(slice.actions.setInvoiceNum(response.data.data));
            dispatch(slice.actions.showSuccess(true));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.showSuccess(false));
            dispatch(slice.actions.hasErrorNum(error));
        }
    };
}

export function postInvoiceNum(InvoiceNumber) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/Admin/Invoice/updateInvoiceNum`,
                InvoiceNumber, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            dispatch(slice.actions.setInvoiceNum(response.data.data));
            dispatch(slice.actions.showSuccess(true));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.showSuccess(false));
            dispatch(slice.actions.hasErrorNum(error));
        }
    };
}

export function postLogBook(invoiceID, status, activity, comment, user_email) {
    const API_URL = process.env.REACT_APP_API_URL;

    const CommentLog = {
        invoiceID,
        status,
        activity,
        comment,
        email: user_email,
    };

    return async () => {
        dispatch(slice.actions.setInvLogbookLoading(true));
        // NOTE TO HASSAN: Potentially include other loading variables to true for the invoice profile page
        try {
            const response = await axios.post(`${API_URL}/api/invoice/invoiceProfile/submitInvoiceLogbook`,
                CommentLog, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setInvLogbookLoading(false));
            dispatch(getLogBook());
            // NOTE TO HASSAN: Potentially include other loading variables to false for the invoice profile page
            // RELOAD ALL DATA for the page, call other APIs present in the page
        }
    };
}

export function getLogBookFilters() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        try {
            const response = await axios.get(`${API_URL}/api/invoice/invoiceProfile/LogBookFilters`);
            dispatch(slice.actions.setInvoiceLogbookFilters(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function fetchInvoiceExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the invoice data in CSV format
            const response = await axios.get(`${API_URL}/api/export/invoices`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Billing Data.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}


export function fetchKFExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the invoice data in CSV format
            const response = await axios.get(`${API_URL}/api/export/kashflow`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'KF Data.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

export function fetchMonthBillBreakdownExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the invoice data in CSV format
            const response = await axios.get(`${API_URL}/api/export/invoices/monthlybilling`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Billing Breakdown.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

// VAULTED
export function fetchBillingPlanExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the billing plan data in CSV format
            const response = await axios.get(`${API_URL}/api/export/billing_plans`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Billing Plans.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}


export default slice.reducer;
