import React, { useState, useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "../../../redux/store";
import AdminContext from "../../../Context/adminContext";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import Dropdown from "../../../Components/DropDownBox/Dropdown";

import styles from "../../Opportunities/Main/Opportunities.module.css";

import {
  setinputValue,
  setSearchTerm,
  setCurrentPage,
  setStatusFilter,
  setTypeFilter,
  setDueFilter,
  setSortDirection,
  setSortColumn,
  getInvoiceList,
  fetchInvoiceExport,
  fetchKFExport,
} from "../../../redux/slices/invoice";
import InvListTable from "../../../Sections/invoicing/InvListTable";
import ProjectTablePagination from "../../Projects/ProjectList/ProjectTablePagination";

function InvoiceList() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];
  const API_URL = process.env.REACT_APP_API_URL;

  const admin = useContext(AdminContext);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const {
    currentPage,
    invoiceList,
    statusFilter,
    typeFilter,
    dueFilter,
    searchTerm,
    inputValue,
    isinvoiceListLoading,
    sortDirection,
    sortColumn,
    error,
  } = useSelector((state) => state.invoice);

  const totalPages = invoiceList.totalRows > 0 ? Math.ceil(invoiceList.totalRows / rowsPerPage) : 0;
  const currentPageValue = invoiceList.totalRows > 0 ? currentPage : 0;

  useEffect(() => {
    dispatch(getInvoiceList(statusFilter, typeFilter, dueFilter, searchTerm, sortColumn, sortDirection, rowsPerPage, currentPage));
    setPaginationLoading(false);
  }, [statusFilter, typeFilter, dueFilter, searchTerm, sortColumn, sortDirection, currentPage]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      dispatch(setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortColumn(column));
      dispatch(setSortDirection('asc'));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handleSearch = (event) => {
    dispatch(setinputValue(event.target.value));
  };

  const handleSearchSubmit = () => {
    dispatch(setSearchTerm(inputValue));
    dispatch(setCurrentPage(1));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handlestatusFilter = (statusFilter) => {
    dispatch(setStatusFilter(statusFilter));
    dispatch(setCurrentPage(1));
  };

  const handleTypeFilter = (typeFilter) => {
    dispatch(setTypeFilter(typeFilter));
    dispatch(setCurrentPage(1));
  };

  const handleDueFilter = (dueFilter) => {
    dispatch(setDueFilter(dueFilter));
    dispatch(setCurrentPage(1));
  };

  return (
    <div className={styles.opportunitiesContainer}>
      <div className={styles.OpportunitiesMainContent}>
        <div className={styles.Header}>
          <div className={styles.OpportunitiesTitle}>Invoice List</div>
          <div className={styles.ButtonContainer}>
            {admin &&
              <React.Fragment>
                <div className={`${styles.Btn} ${isinvoiceListLoading ? styles.AddDisabled : ''}`} onClick={!isinvoiceListLoading ? () => dispatch(fetchInvoiceExport()) : null}>
                  EXPORT BILLING DATA
                </div>

                <div className={`${styles.Btn} ${isinvoiceListLoading ? styles.AddDisabled : ''}`} onClick={!isinvoiceListLoading ? () => dispatch(fetchKFExport()) : null}>
                  EXPORT KF DATA
                </div>
              </React.Fragment>
            }
          </div>
        </div>

        <div className={styles.OpportunitiesTableFilters}>
          <div className={styles.Filters}>
            <div className={styles.statusFilter}>
              <label
                className={styles.FilterLabel}
                htmlFor="statusFilter"
                style={{ fontSize: "12px" }}
              >
                Status:
              </label>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  options={[
                    "All",
                    "Planned",
                    "Draft",
                    "Raised",
                    "Unpaid",
                    "Paid",
                  ]}
                  initialSelectedValue={statusFilter}
                  onValueChange={handlestatusFilter}
                  disabled={isinvoiceListLoading}
                />
              </div>
            </div>
            <div className={styles.statusFilter}>
              <label
                className={styles.FilterLabel}
                htmlFor="statusFilter"
                style={{ fontSize: "12px", marginLeft: 40 }}
              >
                Type:
              </label>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  options={["All", "Invoice", "Credit"]}
                  initialSelectedValue={typeFilter}
                  onValueChange={handleTypeFilter}
                  disabled={isinvoiceListLoading}
                />
              </div>
            </div>
            <div className={styles.statusFilter}>
              <label
                className={styles.FilterLabel}
                htmlFor="statusFilter"
                style={{ fontSize: "12px", marginLeft: 40 }}
              >
                Due:
              </label>
              <div className={styles.dropdownContainer}>
                <Dropdown
                  options={["All", "This Month", "Next Month"]}
                  initialSelectedValue={dueFilter}
                  onValueChange={handleDueFilter}
                  disabled={isinvoiceListLoading}
                />
              </div>
            </div>
          </div>

          <div className={styles.SearchBar}>
            <SearchBar value={inputValue} onChange={handleSearch} onKeyDown={handleKeyDown} disabled={isinvoiceListLoading} onClick={handleSearchSubmit} />
          </div>
        </div>

        {!isinvoiceListLoading ? (
          <>
            <InvListTable
              rows={invoiceList.rows}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={handleSort}
              loading={isinvoiceListLoading}
            />

            <ProjectTablePagination
              currentPage={currentPageValue}
              totalPages={totalPages}
              onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
              onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
              rowsShown={invoiceList.rows.length}
              totalRows={invoiceList.totalRows}
              rowsPerPage={rowsPerPage}
              loading={isinvoiceListLoading}
            />
          </>
        ) : (
          <>
            <InvListTable
              rows={[]}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              handleSort={handleSort}
              loading={isinvoiceListLoading}
            />

            <ProjectTablePagination
              currentPage={currentPageValue}
              totalPages={totalPages}
              onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
              onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
              rowsShown={invoiceList.rows.length}
              totalRows={invoiceList.totalRows}
              rowsPerPage={rowsPerPage}
              loading={isinvoiceListLoading}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default InvoiceList;
