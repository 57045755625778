import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableTh from './ResizableTh';
import { Link } from 'react-router-dom';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import { useSelector, useDispatch } from '../../../redux/store';
import { setSelectedRow } from '../../../redux/slices/rowhighlight';

function ProjectListTable(props) {
  const [columnWidths, setColumnWidths] = React.useState({
    0: 40, // default width for Job No
    1: 120, // default width for Project Address
    2: 100, // default width for Client
    3: 20, // default width for Tags
    4: 40, // default width for Status
    5: 280, // default width for Comment
    6: 40, // default width for Runner
    7: 40, // default width for Next Deadline
    8: 20, // default width for pFB
    9: 70, // default width Fee Burn
    10: 70, // default width for Invoice
    11: 30, // default width for Fee
    12: 40, // default width for Promoted
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
  };

  const tableRef = React.useRef(); // Create a ref for the table
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const selectedRow = useSelector((state) => state.rowhighlight.selectedRow);

  const setColumnWidth = React.useCallback((index, width) => {
    setColumnWidths((prev) => {
      const totalWidth = Object.values(prev).reduce((a, b) => a + b, 0);
      const tableWidth = tableRef.current ? tableRef.current.offsetWidth : 0;

      if (prev[index] !== width && totalWidth - prev[index] + width <= tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        return newWidths;
      }

      if (totalWidth - prev[index] + width > tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        const excessWidth = totalWidth - prev[index] + width - tableWidth;
        const otherColumns = Object.keys(newWidths).filter((key) => key !== index);
        let remainingExcessWidth = excessWidth;

        const shrinkLeft = index > 0 && index < otherColumns.length;
        const sortedColumns = shrinkLeft ? otherColumns.sort((a, b) => a - b) : otherColumns.sort((a, b) => b - a);

        for (const column of sortedColumns) {
          if (newWidths[column] > 50 + remainingExcessWidth / otherColumns.length) {
            newWidths[column] -= remainingExcessWidth / otherColumns.length;
            remainingExcessWidth -= remainingExcessWidth / otherColumns.length;
          } else {
            remainingExcessWidth -= newWidths[column] - 50;
            newWidths[column] = 50;
          }
        }

        return newWidths;
      }

      return prev;
    });
  }, []);

  React.useEffect(() => {
    const scrollPos = localStorage.getItem('scrollPos');
    if (scrollPos && tableRef.current) {
      tableRef.current.scrollTop = parseFloat(scrollPos);
    }
    console.log(props.rows);
  }, []);

  const uniqueRows = React.useMemo(() => {
    const jobNoSet = new Set();
    return props.rows.filter(row => {
      if (jobNoSet.has(row.jobno)) {
        return false;
      } else {
        jobNoSet.add(row.jobno);
        return true;
      }
    });
  }, [props.rows]);

  return (
    <div id="projTableContainer" ref={tableRef}>
      <table>
        <thead>
          <tr>
            {['Job No.', 'Project Address', 'Client', 'Tags', 'Status', 'Comment', 'Runner',
              props.status !== 'On hold' ? 'Next Deadline' : 'Follow Up',
              'pFB', 'Fee Burn', 'Invoice', 'Fee', 'Promoted'].map((header, index) => (
                <ResizableTh onResize={(width) => setColumnWidth(index, width)} index={index} key={index}>
                  <span
                    onClick={props.loading ? null : () =>
                      props.handleSort(
                        header === 'Invoice'
                          ? 'invoicepercent' // Use 'invoicepercent' for the 'Invoice' column
                          : header.toLowerCase().replace(' ', '').replace('.', '') // Continue using existing logic for other headers
                      )
                    }
                    className={props.loading ? 'disable-click' : ''}>
                    {header}
                    {props.sortColumn === (header === 'Invoice' ? 'invoicepercent' : header.toLowerCase().replace(' ', '').replace('.', '')) && (
                      props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                    )}
                  </span>
                </ResizableTh>
              ))}
          </tr>
        </thead>
        <tbody>
          {props.loading ? (
            [...Array(50)].map((_, index) => (
              <tr key={index}>
                {[...Array(13)].map((_, cellIndex) => (
                  <td key={cellIndex} style={{ width: columnWidths[cellIndex] }}>
                    <div className="skeleton" style={{ height: '13px' }}></div>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            uniqueRows.map((row) => (
              <tr
                key={row.jobno}
                onMouseEnter={() => setHoveredRow(row.jobno)}
                onMouseLeave={() => setHoveredRow(null)}
                onClick={() => {
                  navigate(`ProjectProfile#${row.jobno}`);
                  dispatch(setSelectedRow(row.jobno));
                  if (tableRef.current) {
                    localStorage.setItem('scrollPos', tableRef.current.scrollTop);
                  }
                }}
                className={`
                  ${hoveredRow === row.jobno && selectedRow !== row.jobno ? 'row-hover' : ''}
                  ${selectedRow === row.jobno ? 'row-selected' : ''}
                `}
              >
                <td style={{ width: columnWidths[0], maxWidth: '100px' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '40px', textAlign: 'center' }}>{row.jobno}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[1], maxWidth: '200px' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '77px', width: `${columnWidths[1]}px`, maxWidth: '200px' }}>{row.projectaddress}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[2], maxWidth: '200px' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[2]}px` }}>{row.client}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[3], maxWidth: '100px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ display: 'flex', minWidth: '20px', justifyContent: 'center' }}>
                      {row.tags.length > 0 && (
                        <div className="TagContainer">
                          {row.tags.slice(0, 3).map(tag => {
                            const words = tag.split(' ');
                            const abbreviation = words.slice(0, 2).map(word => word[0].toUpperCase()).join('');
                            return (
                              <div key={tag} className="Tag">
                                {abbreviation}
                              </div>
                            );
                          })}
                        </div>
                      )}

                    </div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[4], maxWidth: '100px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '40px' }}>
                      <div className={row.status === 'LIVE' ? 'StatusLive' : row.status === 'COMPLETE' ? 'StatusLive' : row.status === 'ON HOLD' ? 'StatusOnHold' : 'StatusDormant'}>
                        {row.status}
                      </div>
                    </div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[5], maxWidth: '700px' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '50px', width: columnWidths[5], maxWidth: '700px' }}>{row.comment}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[6], maxWidth: '70px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '40px' }}>{row.runner}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[7], maxWidth: '100px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '40px' }}>
                      {console.log(props.status)}
                      {props.status != 'On hold' ? (
                        row.nextdeadline !== '' ? (
                          new Date(row.nextdeadline).toISOString() === new Date(9999, 11, 31).toISOString() ? (
                            'Not Set'
                          ) : (
                            new Date(row.nextdeadline).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: '2-digit'
                            })
                          )
                        ) : 'None'
                      ) : (
                        row.followup !== '' ? (
                          new Date(row.followup).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) ? (
                            <span style={{ color: 'red' }}>
                              {new Date(row.followup).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit'
                              })}
                            </span>
                          ) : (
                            new Date(row.followup).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: '2-digit'
                            })
                          )
                        ) : 'None'
                      )}
                    </div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[8], maxWidth: '60px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '20px' }}>{row.pfb === 199 ? '' : (row.pfb !== '' && !isNaN(parseFloat(row.pfb)) ? `${Math.round(parseFloat(row.pfb) * 100)}%` : '')}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[9], maxWidth: '100px', paddingInline: '15px' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '50px' }}>
                      <ProgressBar
                        currentValue={parseFloat(row.feeburn)}
                        maxValue={parseFloat(row.pfb)}
                        displayMode={'valuePercent'}
                        greenThreshold={70}
                        yellowThreshold={100}
                        orangeThreshold={120}
                      />
                    </div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[10], maxWidth: '100px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '40px' }}>
                      <ProgressBar
                        currentValue={parseFloat(row.invoice)}
                        maxValue={parseFloat(row.fee)}
                        displayMode={'percentage'}
                        variant={'blue'}
                      />
                    </div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[11], maxWidth: '100px', textAlign: 'right' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '30px' }}>{`£${Number(row.fee).toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}</div>
                  </Link>
                </td>

                <td style={{ width: columnWidths[12], maxWidth: '100px', textAlign: 'center' }}>
                  <Link to={`ProjectProfile#${row.jobno}`} style={linkStyle}>
                    <div className="td-content" style={{ minWidth: '40px' }}>{row.promoted !== '' ? new Date(row.promoted).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' }) : ''}</div>
                  </Link>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ProjectListTable;