import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux

// ----------------------------------------------------------------------

let initialState = {
  searchValue: "",
  searchInputValue: "",
  serviceFilter: "All",
  typeFilter: "All",
  serviceOptions: [],
  typeOptions: ["All", "Supplier", "Subcontractor"],
  supplierListLoading: true,
  supplierList: { 'rows': [] },
  sortColumn: "",
  sortDirection: "asc",
  currentPage: 1,
  newSupplier: {},

  error: null,
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {

    // set filter values in redux
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },

    setServiceFilter(state, action) {
      state.serviceFilter = action.payload;
    },

    setTypeFilter(state, action) {
      state.typeFilter = action.payload;
    },

    setSearchInputValue(state, action) {
      state.searchInputValue = action.payload;
    },

    setSupplierList(state, action) {
      state.supplierList = action.payload;
      state.supplierListLoading = false;
    },

    setServiceOptions(state, action) {
      state.serviceOptions = action.payload;
    },

    setSortDirection(state, action) {
      state.sortDirection = action.payload;
    },

    setSortColumn(state, action) {
      state.sortColumn = action.payload;
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    setSuppliersListLoading(state, action) {
      state.supplierListLoading = action.payload;
    },

    setNewSupplier(state, action) {
      state.newSupplier = action.payload;
    },



    // HAS ERROR
    hasError(state, action) {
      state.isinvSummaryTableLoading = false;
      state.error = action.payload;
    },


  },

});

export const {
  setSearchValue,
  setServiceFilter,
  setTypeFilter,
  setSearchInputValue,
  setSortColumn,
  setSortDirection,
  setCurrentPage,
  hasError
} = suppliersSlice.actions;

// Reducer
export function getSuppliersList(serviceFilter, typeFilter, searchValue, sortColumn, sortDirection, rowsPerPage, page) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(suppliersSlice.actions.setSuppliersListLoading(true));
    try {
      // Construct the query string
      const queryParams = new URLSearchParams({
        serviceFilter,
        typeFilter,
        page,
        rowsPerPage,
        searchValue,
        sortColumn,
        sortDirection
      }).toString();

      const response = await axios.get(`${API_URL}/api/suppliers/pageData?${queryParams}`);
      dispatch(suppliersSlice.actions.setSupplierList(response.data));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function createSupplier(formData) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async (dispatch, getState) => {
    try {
      const response = await axios.post(`${API_URL}/api/suppliers/addNew`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      dispatch(suppliersSlice.actions.setNewSupplier(response.data.data));

      // Get the current state values to use as parameters
      const state = getState().suppliers;
      const { serviceFilter, typeFilter, searchValue, sortColumn, sortDirection, currentPage, rowsPerPage } = state;

      // Call getSuppliersList using the current state values
      dispatch(getSuppliersList(serviceFilter, typeFilter, searchValue, sortColumn, sortDirection, rowsPerPage, currentPage));
    } catch (error) {
      console.error(error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export function fetchServiceOptions() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async (dispatch) => {
    try {
      const result = await axios.get(`${API_URL}/api/suppliers/serviceOptions`);
      dispatch(suppliersSlice.actions.setServiceOptions(result.data));
    } catch (error) {
      console.error('Error fetching service options:', error);
      dispatch(suppliersSlice.actions.hasError(error));
    }
  };
}

export default suppliersSlice.reducer;