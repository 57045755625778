import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../Settings/Settings.module.css';
import { useMsal } from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import InvoiceNumPopup from '../../../Components/InvoiceNumPopUp/InvoiceNumPopUp';
import { useSelector, useDispatch } from "../../../redux/store";
import { getCurrentInvNum, postInvoiceNum, postInvoiceNumUnpaid } from "../../../redux/slices/invoice";
import { getConfiguration, updateSTDTemplateResponsibilities } from "../../../redux/slices/settings";

import {
  fetchWIPExport,
} from "../../../redux/slices/project";

// Import notistack's enqueueSnackbar
import { useSnackbar } from 'notistack';
import ResponsibilitiesForm from '../../../Sections/settings/responsibilitiesForm';

function Settings() {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(0);
  const { accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const account = accounts[0];
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [InvoiceMode, setInvoiceMode] = useState(null);
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [showFormResPopup, setShowFormResPopup] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const { invoiceNum, showSuccess, errorInvNum } = useSelector((state) => state.invoice);
  const { showSettingsSuccess } = useSelector((state) => state.settings);

  // Access the enqueueSnackbar function from notistack
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading state
      try {
        const fetchRequestedLeaveList = await fetch(`${API_URL}/api/AdminHoliday/RequestedLeaveList?ApproversEmail=${encodeURIComponent(account.username)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const fetchCalendarData = await fetch(`${API_URL}/api/CalendarData`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const fetchHolidayOverview = await fetch(`${API_URL}/api/Admin/Leave/HolidayOverviewRows`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        dispatch(getConfiguration());
        dispatch(getCurrentInvNum());

        await Promise.all([fetchRequestedLeaveList, fetchCalendarData, fetchHolidayOverview]);
        setIsDataFetched(true); // Mark that data has been successfully fetched

      } catch (err) {
        console.error("Error fetching data:", err);
        enqueueSnackbar('Failed to fetch data', { variant: 'error' });
      } finally {
        setLoading(false); // Ensure loading state is turned off regardless of success/failure
      }
    };

    fetchData();
  }, [reload, account.username, API_URL, dispatch, enqueueSnackbar]); // Add enqueueSnackbar as a dependency

  const navigate = useNavigate();
  const location = useLocation();

  const handleInvoiceSubmit = async (invoiceNumber) => {
    try {
      if (InvoiceMode === 'ADJ') {
        console.log("Submitted Invoice Number:", invoiceNumber);
        await dispatch(postInvoiceNum(invoiceNumber)); // Dispatch action to update invoice number
      }
      else {
        // Handle the case when mode is 'UNPAID'
        await dispatch(postInvoiceNumUnpaid(invoiceNumber));
      }
    } catch (error) {
      // Show error message
      enqueueSnackbar('Failed to update invoice number', { variant: 'error' });
    }

    setShowInvoicePopup(false); // Close the popup after submission
  };

  const handleResponsbilityFormSubmit = (formData) => {
    console.log("Form Data Submitted:", formData);

    const modifiedFormData = {
      ...formData,
      completedBy: account.username, // Add 'completedBy' key with value 'account'
    };

    dispatch(updateSTDTemplateResponsibilities(modifiedFormData));
  };


  const closeResponsiblityForm = () => {
    setShowFormResPopup(false);
  };

  useEffect(() => {
    if (showSuccess, showSettingsSuccess) {
      enqueueSnackbar('Updated successfully!', {
        variant: "success",
      });
    }
  }, [showSuccess, showSettingsSuccess]);

  // useEffect(() => {
  //   if (errorInvNum) {
  //     enqueueSnackbar('Cannot use this invoice number!', {
  //       variant: "error",
  //     });
  //   }
  // }, [errorInvNum]);

  return (
    <>
      {location.pathname === "/Admin/Settings" && (
        isDataFetched ? (
          <div className={styles.mainContent}>

            <div className={styles.content}>
              <div className={styles.title}>WIP Excel Download</div>
            </div>
            <div className={styles.content}>
              <div className={styles.LeaveAdjustments}>
                <div
                  className={styles.textbox}
                  onClick={async () => {
                    setLoading(true);  // Set loading to true before the export begins
                    await dispatch(fetchWIPExport());  // Wait for the export process to complete
                    setLoading(false);  // Set loading to false after the export is done
                  }}
                >
                  WIP Download
                </div>
              </div>
            </div>

            <div className={styles.content}>
              <div className={styles.title}>Holiday</div>
            </div>
            <div className={styles.content}>
              <div className={styles.LeaveAdjustments}>
                <div className={styles.textbox}> Set Allowances</div>
                <div className={styles.textbox} onClick={() => navigate(`EndOfYearAdjustment`)}> End of Year Adjustment</div>
              </div>
            </div>

            <div className={styles.content}>
              <div className={styles.title}>Invoice</div>
            </div>
            <div className={styles.content}>
              <div className={styles.LeaveAdjustments}>
                <div className={styles.textbox} onClick={() => { setShowInvoicePopup(true); setInvoiceMode('ADJ') }}> Invoice Number Adjustment</div>
                <div className={styles.textbox} onClick={() => { setShowInvoicePopup(true); setInvoiceMode('UNPAID') }}> Mark Invoice as Unpaid</div>
              </div>

            </div>

            <div className={styles.content}>
              <div className={styles.title}>Form Responsibilities</div>
            </div>
            <div className={styles.content}>
              <div className={styles.LeaveAdjustments}>
                <div className={styles.textbox} onClick={() => setShowFormResPopup(true)}> Standards/Templates</div>
              </div>
            </div>

            {loading ? (
              <div className={styles.ProfileHolidayOverlay}>
                <CircularProgress size={65} />
              </div>
            ) : (
              isResponseShown && (
                <div className={styles.ProfileHolidayOverlay}>
                  <div className={styles.ProfileHolidayResponseBox}>
                    <div
                      style={{
                        fontSize: 16,
                        fontFamily: 'PT Sans',
                        textAlign: 'center',
                        margin: '20px',
                        marginLeft: '25px',
                        marginRight: '25px'
                      }}
                      dangerouslySetInnerHTML={{ __html: apiResponse }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        className={styles.ProfileHolidayButton}
                        onClick={() => setIsResponseShown(false)}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}

            <InvoiceNumPopup
              data={invoiceNum}
              isVisible={showInvoicePopup}
              onClose={() => setShowInvoicePopup(false)}
              onSubmit={handleInvoiceSubmit}
              mode={InvoiceMode}
            />

            {showFormResPopup && (
              <ResponsibilitiesForm
                onSubmit={handleResponsbilityFormSubmit}
                onClose={closeResponsiblityForm}
              />
            )}
          </div>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center' }}>
            <CircularProgress size={65} />
          </Box>
        )
      )}
      <Outlet />
    </>
  );
}

export default Settings;
