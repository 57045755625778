import React, { useState, useEffect } from 'react';
import DateRangePickerComponent from './DateRangePickerComponent';
import './Holiday.css';
import BookedLeaveTable from './BookedLeaveTable';
import Dropdown from "../../../Components/DropDownSelect/Dropdown";
import CompanyCalander from "../../../Components/ResourceCalander/ResourceCalander";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useMsal } from "@azure/msal-react";
import '../../../Components/YesNoButtonsCSS/YesNoButtons.css';

const leave_type_options = [
  "Holiday",
  "Study Leave",
  "University",
  "Jury Service",
  "Unpaid",
];

const leave_length_options = [
  "Full Day",
  "Half Day (AM)",
  "Half Day (PM)"
];

function Holiday() {
  const [reload, setReload] = useState(0);
  const [leaveType, setLeaveType] = useState("");
  const [leaveLength, setLeaveLength] = useState("");
  const [comment, setComment] = useState("");
  const [calendarData, setCalendarData] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { accounts } = useMsal();
  const account = accounts[0];
  const [rows, setRows] = useState([]);
  const [holidayDays, setHolidayDays] = useState([]);
  const [studyDays, setStudyDays] = useState([]);
  const [RequestedHolidayDays, setRequestedHolidayDays] = useState([]);
  const [RequestedStudyDays, setRequestedStudyDays] = useState([]);
  const [totalHolidayAllowance, setTotalHolidayAllowance] = useState([]);
  const [totalStudyAllowance, setTotalStudyAllowance] = useState([]);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [leaveDates, setLeaveDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
  ]);

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  }

  useEffect(() => {
    const fetchHolidaysList = fetch(`${API_URL}/api/ProfileHoliday/userHolidaysList?userEmail=${encodeURIComponent(account.username)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log(`${API_URL}/api/ProfileHoliday/userHolidaysList?userEmail=${encodeURIComponent(account.username)}`)

    const fetchAllowance = fetch(`${API_URL}/api/ProfileHoliday/userAllowance?userEmail=${encodeURIComponent(account.username)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const fetchCalendarData = fetch(`${API_URL}/api/CalendarData`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    Promise.all([fetchHolidaysList, fetchAllowance, fetchCalendarData])
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(([fetchedHolidaysList, fetchedAllowance, fetchedCalendarData]) => {
        setRows(fetchedHolidaysList);
        setCalendarData(fetchedCalendarData);
        setHolidayDays(fetchedAllowance.holidayAllowance);
        setStudyDays(fetchedAllowance.studyAllowance);
        setTotalHolidayAllowance(fetchedAllowance.totalHolidayAllowance);
        setTotalStudyAllowance(fetchedAllowance.totalStudyAllowance);
        setRequestedStudyDays(fetchedAllowance.studyRequested);
        setRequestedHolidayDays(fetchedAllowance.holidayRequested);
        setIsDataFetched(true);
      });
  }, [reload]);


  const handleCancelationReload = async (currentId) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/ProfileHoliday/CancelingLeaveRequest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: account.username, leaveID: currentId }),
      });
      if (response.ok) {
        const data = await response.json();
        setApiResponse(data.message); // We only need the message part of the response
      } else {
        setApiResponse('Error Canceling Leave Request (backend ran into a logical issue, please let a DevOps engineer know)');
      }
      setReload(reload + 1);
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    } finally {
      setLoading(false);
    }
    setIsResponseShown(true);
  };


  const toLocalISOString = (date) => {
    let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate());
  }

  const handleSubmit = async () => {
    setLoading(true);

    const bookingDetails = {
      leaveType,
      leaveLength,
      leaveDates: leaveDates.map(({ startDate, endDate, key }) => ({
        startDate: toLocalISOString(startDate),
        endDate: toLocalISOString(endDate),
        key
      })),
      comment,
      email: account.username,
    };

    try {
      const response = await fetch(`${API_URL}/api/ProfileHoliday/LeaveRequestSubmit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookingDetails),
      });

      if (response.ok) {
        const data = await response.json();
        setApiResponse(data.message); // We only need the message part of the response
      } else {
        setApiResponse('Error Submitting Leave Request (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error('Error:', error);
      setApiResponse('Error occurred');
    }
    setReload(reload + 1);
    setLeaveType("");
    setLeaveLength("");
    setLeaveDates([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ]);
    setComment("");
    setIsResponseShown(true);
    setLoading(false);
  };

  const CalanderAPItrigger = async (changeData) => {
    try {
      const queryParams = new URLSearchParams(changeData).toString();
      const response = await fetch(`${API_URL}/api/CalendarData?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCalendarData(data);
      }
    } catch (error) {
      console.error('Error:', error);
      setCalendarData('Error occurred');
    }
  }

  return (
    <>
      {isDataFetched ? (
        <div className='ProfileMainContent'>
          <div className='holidayManagment'>
            <div className='bookedLeaveInfo'>
              <div className='title' style={{ width: '100%' }}>Leave Overview</div>
              <div className='daysLeft'>
                <div style={{ marginRight: '6px' }} className='BookedAndAllowanceDays'>
                  <div style={{ marginBottom: '8px' }} className='RequestedDays'>
                    <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalHolidayAllowance}</div>
                    <div className='RequestedDaysText'>
                      <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Allowance</span>
                    </div>
                  </div>
                  <div className='RequestedDays'>
                    <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalHolidayAllowance - holidayDays}</div>
                    <div className='RequestedDaysText'>
                      <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Booked    </span>
                    </div>
                  </div>
                </div>
                <div className='holidayDays'>
                  <div className='holidayDaysNum'>{holidayDays}</div>
                  <div className='holidayDaysText'>
                    <span style={{ display: 'block', marginTop: '4px', fontSize: '14px' }}>Holiday</span>
                    <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Days</span>
                    <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Remaining</span>
                  </div>
                </div>
                <div className='studyDays'>
                  <div className='studyDaysNum'>{studyDays}</div>
                  <div className='studyDaysText'>
                    <span style={{ display: 'block', marginTop: '3px', fontSize: '14px' }}>Study</span>
                    <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Days</span>
                    <span style={{ display: 'block', marginTop: '-3px', fontSize: '14px' }}>Remaining</span>
                  </div>
                </div>
                <div style={{ marginLeft: '6px' }} className='BookedAndAllowanceDays'>
                  <div style={{ marginBottom: '8px' }} className='RequestedDays'>
                    <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalStudyAllowance}</div>
                    <div className='RequestedDaysText'>
                      <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Allowance</span>
                    </div>
                  </div>
                  <div className='RequestedDays'>
                    <div style={{ display: 'block', marginTop: '0px', fontSize: '30px' }} className='studyDaysNum'>{totalStudyAllowance - studyDays}</div>
                    <div className='RequestedDaysText'>
                      <span style={{ display: 'block', marginTop: '3px', fontSize: '14px', width: '62px' }}>Booked    </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='bookedLeaveBox' style={{ display: isDataFetched ? 'block' : 'none' }}>
                <BookedLeaveTable rows={rows} setRows={setRows} setIsDataFetched={setIsDataFetched} handleCancelationReload={handleCancelationReload} />
              </div>
            </div>

            <div className='bookedLeaveInfo'>
              <div className='title' style={{ width: 'calc(100% - 24px)', marginLeft: '24px' }}>Book Leave</div>
              <div className='bookingLeave'>
                <div className='bookingLeaveInteraction'>
                  <div className='selectsSection'>
                    <div className='typeOfLeave'>
                      <div className='bookingLeaveLabel'>Type of Leave</div>
                      <div className="dropdowns">
                        <Dropdown options={leave_type_options} selectedValue={leaveType} onValueChange={setLeaveType} />
                      </div>
                    </div>
                    <div className='leaveLength'>
                      <div className='bookingLeaveLabel'>Length of Leave</div>
                      <div className="dropdowns">
                        <Dropdown options={leave_length_options} selectedValue={leaveLength} onValueChange={setLeaveLength} />
                      </div>
                    </div>
                  </div>
                  <div className='bookingLeaveRow'>
                    <div className='bookingLeaveLabel'>Date of Leave</div>
                    <DateRangePickerComponent selectedDates={leaveDates} onDatesChange={setLeaveDates} />
                  </div>
                  <div className='bookingLeaveRowComment'>
                    <textarea className={isFocused ? 'bookingLeaveComment' : 'nonBookingLeaveComment'} id='comment' placeholder={isFocused ? '' : 'Comment...'} onFocus={handleFocus} onBlur={handleBlur} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                  </div>
                  <div className='submitdiv'>
                    <button className='bookingLeaveSubmit' onClick={handleSubmit}>SUBMIT</button>
                  </div>
                </div>

                {loading ? // Here is the code for displaying error/success messages
                  <div className='ProfileHolidayOverlay'>
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress size={65} />
                    </Box>
                  </div>
                  :
                  isResponseShown && (
                    <div className='ProfileHolidayOverlay'>
                      <div className='ProfileHolidayResponseBox'>
                        <div
                          style={{
                            fontSize: 16,
                            fontFamily: 'PT Sans',
                            textAlign: 'center',
                            margin: '20px',
                            marginLeft: '25px',
                            marginRight: '25px'
                          }}
                          dangerouslySetInnerHTML={{ __html: apiResponse }}
                        >
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <button className='ProfileHolidayButton' onClick={() => setIsResponseShown(false)}>
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className='title2'>Company Leave Calendar</div>
          <CompanyCalander resourceData={calendarData} CalanderAPItrigger={CalanderAPItrigger} UserEmail={account.username} />

        </div>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center' }}>
          <CircularProgress size={65} />
        </Box>
      )}
    </>
  );
}

export default Holiday;